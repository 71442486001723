/**
 * Tomas Costantino
 * 
 * Website footer
 */

.footer {
    text-align: center;
    padding: 2rem 1rem;
    background-color: #1a1a1a;
    color: #f5f5f5;
}

.footer-content {
    max-width: 1160px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.social-links {
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.social-links a {
    color: #f5f5f5;
    margin: 0 0.5rem;
    transition: color 0.3s ease;
}

.social-links a:hover {
    color: #007bff;
}

@media (min-width: 768px) {
    .footer-content {
        flex-direction: row;
    }
}