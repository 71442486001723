/**
 * Tomas Costantino
 * 
 * Website project in portfolio
 */

.project-card {
    padding: 20px;
    transition: transform 0.3s ease;
}

.project-card:hover {
    transform: scale(1.02);
}

.icon {
    font-size: 30px;
    margin-right: 10px;
}