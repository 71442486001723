/**
 * Tomas Costantino
 *
 * App css
 */

body {
  margin-top: 75px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  padding-top: 56px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo {
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
  letter-spacing: 1px;
  text-decoration: none;
}