.contact-section {
    padding: 2rem;
    background-color: #cccccc;
    text-align: center;
}
.contact-form {
    max-width: 600px;
    display: grid;
    flex-direction: column;
    align-items: center;
    margin: auto;

}
.contact-form input, 
.contact-form textarea,
.submit-button {
    margin: 0.5rem;
    padding: 0.5rem;
    width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
    border: none;
    border-radius: 5px;
    box-sizing: border-box;
}

.submit-button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.contact-form button:hover {
    background-color: #0056b3;
}