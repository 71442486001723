/**
 * Tomas Costantino
 * 
 * Website about me
 */

.about-section {
    padding: 20px;
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.about-container {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 2rem;
    align-items: center;
    max-width: 1200px;
    margin: auto;
}

.about-image img {
    width: 100%;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-text h2 {
    text-align: left;
    color: #333;
    font-size: 2.2rem;
}

.about-text p {
    text-align: left;
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 1rem;
}


@media (max-width: 1024px) {
    .about-container {
        grid-template-columns: 1fr;
        text-align: center;
    }

    .about-text h2,
    .about-text p {
        text-align: center;
    }

    .about-image {
        display: flex;
        justify-content: center;
    }

    .about-image img {
        width: 50%;
    }
}