/**
 * Tomas Costantino
 * 
 * Website portfolio
 */

.portfolio-section {
    padding: 4rem 0;
    background-color: #f3f3f3;
    text-align: center;
}

.project-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2rem;
    max-width: 90%;
    margin: auto;
}

.project-link {
    text-decoration: none;
    color: inherit;
}