/**
 * Tomas Costantino
 * 
 * Website services
 */


.services-section {
    padding: 4rem 0;
    background-color: #f7f7f7;
    text-align: center;
}

.service-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 1rem;
    max-width: 1200px;
    margin: auto;
}

.service {
    transition: transform 0.4s ease;
}

.service:hover {
    transform: scale(1.04);
}

.service-icon {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: #007bff;
}

@media (max-width: 768px) {
    .service-list {
        grid-template-columns: 1fr;
    }
}